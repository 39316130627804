

















































































































import jiff from 'jiff';
import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch} from "vue-property-decorator";
import { Parametro, SmtpEmpresa, Usuario } from '@/core/models/geral';
import { ParametroService, UsuarioService } from '@/core/services/geral';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class ListaParametro extends PageBase {

    itemOriginal:Parametro = new Parametro();
    item: Parametro = new Parametro();
    service = new ParametroService();

    valid = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }
    tabActive: any = {};

    smtpEmpresa: SmtpEmpresa = new SmtpEmpresa();
    dialogSmtp: boolean = false;
    editaSmtp: boolean = false;

    usuarios: Usuario[] = [];
    usuariosSelected: any[] = [];

    headerSMTP: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Descrição', value: 'descricao' },
        { text: 'E-mail', value: 'email' },
        { text: 'SMTP', value: 'smtp' },
        { text: 'Porta', value: 'porta' },
        { text: 'Usuário', value: 'usuario' },
    ]; 

    smtps: any[] = [];

    mounted() {
        this.Carregar();
    }

    Carregar(){
        this.usuariosSelected = [];
        this.service.ObterPorId(this.app.empresaId, "SMTPEmpresas").then(
            res=>{
                this.item = res.data;
                this.smtps = this.item.smtpEmpresas;
                if(this.item.id != 0){
                    this.itemOriginal = jiff.clone(this.item)
                }
                this.item.agendamentoNotificacaoUsuarioIds.split(",").forEach(id => {
                    this.usuariosSelected.push(parseInt(id));
                });
            },
            err=> AlertSimpleErr("Aviso!", err)
        );
        new UsuarioService().ListarTudo().then(
            res => {
                this.usuarios = res.data.items;
            }
        )        
    }

    Salvar(){

        //TRANSFORMAR LISTA IDS EM STRING
        var ids = '';
        this.usuariosSelected.forEach((id, index) => {
            if(index == 0)
                ids += `${id}`;
            else
                ids += `,${id}`;
        });

        this.item.agendamentoNotificacaoUsuarioIds = ids;

        let patchModel = jiff.diff(this.itemOriginal, this.item, false);

        this.service.Salvar(patchModel, this.item.id).then(
            res=>{
                AlertSimpleRes("Aviso!", res);
                this.Carregar();
            },
            err=>{
                AlertSimpleErr("Aviso!", err)
            }
        )
    }    

    SalvarSmtp(){
        if(this.editaSmtp == false){
            this.item.smtpEmpresas.push(this.smtpEmpresa);
        }
        this.dialogSmtp = false;
    }

    AbrirDialogSMTP(smtp?: SmtpEmpresa){

        if(smtp){
            this.smtpEmpresa = smtp;
            this.editaSmtp = true;
        }
        else{
            this.smtpEmpresa = new SmtpEmpresa()
            this.smtpEmpresa.parametroId = this.item.id;
            this.editaSmtp = false;
            
        }

        this.dialogSmtp = true;
    }

    ExcluirSMTP(smtp: SmtpEmpresa){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.smtpEmpresas.indexOf(smtp);
                context.item.smtpEmpresas.splice(index,1);
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
